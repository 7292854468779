<template>
  <div class="ls-add-admin">
    <div class="ls-card">
      <el-page-header @back="$router.go(-1)" content="新增用户订单" />
    </div>

    <div class="ls-card m-t-16 p-20">
      <el-form :rules="rules" ref="form" :model="form" :loading="loading" label-width="120px" size="small">
        <!-- 入境时间 -->
        <!-- <el-form-item label="入境时间">
			<el-date-picker value-format="yyyy-MM-dd" editable type="date" v-model="form.date" style="width: 200px" :picker-options="pickerOptions" />
        </el-form-item> -->
        <!-- 渠道选择 -->
        <el-form-item label="渠道选择：" prop="source" label-width="100px">
        	<el-radio-group v-model="form.source">
        		<el-radio :label="2">
        			<span>飞猪</span>
        		</el-radio>
        		<el-radio :label="3">
        			<span>携程</span>
        		</el-radio>
        		<el-radio :label="4">
        			<span>小程序</span>
        		</el-radio>
        		<el-radio :label="5">
        			<span>线下同业</span>
        		</el-radio>
        	</el-radio-group>
        </el-form-item>
		
		<!-- 同业编码 -->
		<el-form-item label="同业编码" v-if="form.source === 5" prop="store_code">
		  <el-input class="ls-input" v-model="form.store_code" maxlength="10" show-word-limit placeholder="请输入同业编码">
		  </el-input>
		</el-form-item>
		
        <!-- 第三方订单号码 -->
        <el-form-item label="第三方订单号码"  prop="third_no">
          <el-input class="ls-input" v-model="form.third_no" maxlength="30" show-word-limit placeholder="请输入第三方订单号码">
          </el-input>
		  <el-button size="small" type="primary" @click="createOrderNo">自动生成订单号</el-button>
        </el-form-item>
        <!-- 人数 -->
        <el-form-item label="办理人数">
		  <el-input-number v-model="form.num" :min="1" :max="50" label="办理人数"></el-input-number>
        </el-form-item>
      </el-form>
    </div>

    <!-- 底部保存或取消 -->
    <div class="bg-white ls-fixed-footer">
      <div class="row-center flex" style="height: 100%;">
        <el-button size="small" @click="$router.go(-1)">取消</el-button>
        <el-button size="small" type="primary" @click="handleSubmit()">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
		createOrderByUser,
		apiCreateOrderNo
		// getCateMark,
	} from '@/api/visa/order';
	
	export default {
		name: 'visa',
		components: {},
		props: {},
		data() {
			return {
				loading:false,
				form:{
					source:'',
					date:'',
					third_no:'',
					store_code:'',
					num:1
				},
				pickerOptions: {
					//选择第一次时间时触发
					disabledDate: time => { //通过disabledDate设置某个时间范围是否禁用可选
						let pickerType = time.getTime() < this.time
						//pickerType为布尔值，返回false或true；
						return pickerType
					}
				},
				rules: {
					source: [{
							required: true,
							message: '来源不能为空',
							trigger: 'blur'
						},
					],
					third_no: [{
							required: true,
							message: '请输入第三方订单号',
							trigger: 'blur'
						}
					],
				}
			}
		},
		methods: {
			  getdate(dates) {
			  	const date = new Date(dates);
			  	const year = date.getFullYear();
			  	const month = (date.getMonth() + 1).toString().padStart(2, '0');
			  	const day = date.getDate().toString().padStart(2, '0');
			  	return year + '-' + month + '-' + day
			  },
			  createOrderNo(){
				  apiCreateOrderNo()
						.then(async (res) => {
							this.loading = false;
							this.form.third_no = res.order_no
						})
						.catch((res) => {
							this.loading = false;
							return this.$message({
								type: 'error',
								message: "生成失败！"
							});
						})
			  },
			  //创建订单
			  handleSubmit() {
			  	if (this.form.source == '') {
			  		return this.$message({
			  			type: 'error',
			  			message: '请选择来源!'
			  		});
			  	}
			  	if (this.form.third_no == '') {
			  		return this.$message({
			  			type: 'error',
			  			message: '请输入第三方订单号!'
			  		});
			  	}
			  	this.loading = true;
			  	this.form.date = this.getdate(this.form.date)
			  	createOrderByUser(this.form)
			  		.then(async (res) => {
			  			this.loading = false;
			  			this.$message({
			  				type: 'success',
			  				message: '创建成功'
			  			});
			  			// setTimeout(() => {
			  				return this.$router.push({
			  					path: '/visa/edit?id='+res.order_id
			  				});
			  			// }, 500);
			  			return ;
			  		})
			  		.catch((res) => {
			  			this.loading = false;
			  			return this.$message({
			  				type: 'error',
			  				message: "创建失败！"
			  			});
			  		})
			  }
	   }
		
}
</script>

<style lang="scss" scoped>
.ls-add-admin {
  padding-bottom: 80px;

  .ls-input {
    width: 380px;
  }
}
</style>
